<template>
  <el-dialog
    title="声明发布"
    :visible.sync="dialogVisible"
    width="620px"
    @close="dialogClose"
    @open="dialogOpen"
  >
    <el-form
      v-if="dialogVisible"
      ref="form"
      v-loading="loading"
      :model="form"
      label-width="80px"
    >
      <div class="flex-start">
        <el-form-item
          label="历史版本"
          prop="statementId"
          :rules="{ required: true, message: '请选择版本号', trigger: 'change'}"
        >
          <el-select
            v-model="form.statementId"
            class="mr10"
            :disabled="form.id ? true : false"
            placeholder="请选择历史版本"
            @change="historyVersionChange"
          >
            <el-option
              v-for="(item,index) in historyVersionOption"
              :key="index"
              :label="item.statementName"
              :value="item.statementId"
            />
          </el-select>
        </el-form-item>
        <el-form-item
          class="label-no-width"
          label=""
          prop="version"
          :rules="{ required: true, message: '请选择版本号', trigger: 'change'}"
        >
          <el-select
            v-model="form.version"
            placeholder="请选择版本号"
          >
            <el-option
              v-for="(item,index) in versions"
              :key="index"
              :label="item.label"
              :value="item.value"
            />
          </el-select>
        </el-form-item>
      </div>

      <el-form-item label="备注说明">
        <el-input
          v-model="form.remark"
          type="textarea"
        />
      </el-form-item>
    </el-form>
    <div
      slot="footer"
      class="dialog-footer"
    >
      <el-button @click="dialogVisible = false">
        取 消
      </el-button>
      <el-button
        type="primary"
        :disabled="loading"
        @click="submitForm"
      >
        提 交
      </el-button>
    </div>
  </el-dialog>
</template>

<script>
import { saveOrUpdateStatement, QueryHistoryOptions } from '@/api/enterprise/statement'
import { success, error } from '@core/utils/utils'

export default {
  data() {
    return {
      form: {
        id: '',
        remark: '',
        statementId: '',
        version: '',
      },
      dialogVisible: false,
      historyVersionOption: [],
      versions: [],
      loading: false,
    }
  },
  methods: {
    dialogOpen() {
      this.getStatementHistoryVersion()
    },
    dialogClose() {
      this.form = {
        id: '',
        remark: '',
        statementId: '',
        version: '',
      }
      this.$refs.form.resetFields()
    },
    getStatementHistoryVersion() {
      QueryHistoryOptions().then(res => {
        if (res.data.code === 0) {
          this.historyVersionOption = res.data.data
          if (this.historyVersionOption.length > 0 && this.form.statementId) {
            this.versions = []
            this.historyVersionOption.forEach(h => {
              if (h.statementId === this.form.statementId) {
                h.versionList.forEach(v => {
                  this.versions.push({ label: v, value: v })
                })
              }
            })
          }
        }
      })
    },
    historyVersionChange() {
      this.versions = []
      this.historyVersionOption.forEach(h => {
        if (h.statementId === this.form.statementId) {
          h.versionList.forEach(v => {
            this.versions.push({ label: v, value: v })
          })
        }
      })
    },
    // eslint-disable-next-line vue/no-dupe-keys
    submitForm() {
      // eslint-disable-next-line consistent-return
      this.$refs.form.validate(valid => {
        // eslint-disable-next-line no-empty
        if (valid) {
          this.loading = true
          saveOrUpdateStatement(this.form).then(res => {
            this.loading = false
            const resData = res.data
            if (resData.code === 0) {
              success(resData.msg)
              this.dialogVisible = false
              this.$emit('handleFilter')
            } else {
              error(resData.msg)
            }
          })
        } else {
          return false
        }
      })
    },
  },
}
</script>

<style>
  .label-no-width .el-form-item__content{
    margin-left: 0 !important;
  }
</style>
