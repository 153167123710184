<template>
  <div>
    <b-card>
      <div class="filter-wrap flex-between">
        <SearchFilter @handleFilter="handleFilter" :search.sync="listQuery.SearchStr" />
        <el-button
                v-if="savePerm"
                type="primary"
                size="small"
                icon="el-icon-plus"
                @click="saveDialogShow"
        >
          发布声明
        </el-button>
      </div>
      <div class="table-wrap">
        <FilterSelectList
          :list-query.sync="listQuery"
          :select-list.sync="selectList"
          @handleFilter="handleFilter"
        />
        <el-table
          v-loading="loading"
          fit
          border
          :max-height="pageH - 150"
          highlight-current-row
          :data="tableData"
          stripe
          style="width: 100%"
        >
          <el-table-column
                  prop="statementName"
                  :show-overflow-tooltip="true"
                  label="声明名称"
          />
          <el-table-column
            prop="productName"
            :show-overflow-tooltip="true"
            label="业务名称"
          >
            <template
              slot="header"
              slot-scope="scope"
            >
              <TableHeaderFilterDropdown
                :key-str="'productId'"
                :select-list.sync="selectList"
                :label="'业务名称'"
                :value.sync="listQuery.productId"
                :dropdown-options="productOptions"
                @handleFilter="handleFilter"
              />
            </template>
          </el-table-column>
          <el-table-column
            prop="version"
            :show-overflow-tooltip="true"
            label="声明版本"
          />
          <el-table-column
            :show-overflow-tooltip="true"
            prop="accessUrl"
            label="访问地址"
          >
            <template slot-scope="scope">
              <a
                :href="scope.row.accessUrl"
                target="_blank"
              >{{ scope.row.accessUrl }}</a>
            </template>

          </el-table-column>
          <el-table-column
            width="80"
            prop="status"
            label="状态"
          >
            <template
              slot="header"
              slot-scope="scope"
            >
              <TableHeaderFilterDropdown
                :key-str="'status'"
                :select-list.sync="selectList"
                :label="'状态'"
                :value.sync="listQuery.status"
                :dropdown-options="statusOptions"
                @handleFilter="handleFilter"
              />
            </template>
            <template slot-scope="{row}">
              <b-badge
                class="d-flex justify-content-center"
                style="align-items: center"
                pill
                :variant="statusColorObj[row.status]"
              >
                {{ statusObj[row.status] }}
              </b-badge>
            </template>
          </el-table-column>
          <el-table-column
            prop="publishTime"
            width="100"
            label="发布时间"
          >
            <template slot-scope="{row}">
              {{ formatDateStr(row.publishTime) }}
            </template>
          </el-table-column>
          <el-table-column
            prop="remark"
            label="备注"
            :show-overflow-tooltip="true"
          />
          <el-table-column
            label="操作"
            width="140px"
          >
            <template slot-scope="{row}">
              <el-link
                class="mr10"
                :underline="false"
                type="primary"
                @click="saveDialogShow(2,row)"
              >
                <el-tooltip
                  content="修改"
                  placement="top"
                  effect="light"
                >
                  <i class="el-icon-edit" />
                </el-tooltip>
              </el-link>
              <el-link
                class="mr10"
                :underline="false"
                type="primary"
                @click="switchStatus(row.id,row.status)"
              >
                <el-tooltip
                  v-if="row.status && updatePerm"
                  :content="row.status === 1 ? '停用' :'启用'"
                  placement="top"
                  effect="light"
                >
                  <i :class="row.status === 1 ? 'icon-tingyong' : 'icon-qiyong'" class="iconfont"/>
                </el-tooltip>
              </el-link>
              <el-link
                :underline="false"
                class="mr10"
                type="primary"
              >
                <el-tooltip
                  content="预览"
                  placement="top"
                  effect="light"
                >
                  <a
                    :href="row.accessUrl"
                    target="_blank"
                  ><i class="el-icon-document" /></a>
                </el-tooltip>
              </el-link>
              <el-link
                      :underline="false"
                      type="primary"
                      @click="del(row)"
              >
                <el-tooltip
                        content="删除"
                        placement="top"
                        effect="light"
                >
                  <i class="el-icon-delete" />
                </el-tooltip>
              </el-link>
            </template>
          </el-table-column>
        </el-table>
        <pagination
                v-show="total>0"
                :total="total"
                :page.sync="listQuery.page"
                :limit.sync="listQuery.pageSize"
                @pagination="getList"
        />
      </div>
    </b-card>
    <StatementPulish
      ref="statementPulish"
      @handleFilter="handleFilter"
    />
  </div>
</template>

<script>
// eslint-disable-next-line import/extensions
import pagination from '@core/components/pagination/Pagination'
// eslint-disable-next-line import/extensions
import FilterSelectList from '@core/components/filter-select-list/FilterSelectList'
// eslint-disable-next-line import/extensions
import TableHeaderFilterDropdown from '@core/components/table-header-fliter-dropdown/TableHeaderFilterDropdown'
// eslint-disable-next-line import/extensions
import StatementPulish from '@/views/statement/StatementPulish'
// eslint-disable-next-line import/no-cycle
import {
  getStatementPublishListByPage,
  updateStatementPublishStatus,
  DeleteRelease,
} from '@/api/enterprise/statement'
// eslint-disable-next-line import/no-cycle
import { error, findBtnPerm, success } from '@core/utils/utils'
// eslint-disable-next-line import/no-cycle
import { getAllProduct } from '@/api/enterprise/product'

export default {
  components: {
    pagination,
    StatementPulish,
    FilterSelectList,
    TableHeaderFilterDropdown,
  },
  data() {
    return {
      statusOptions: [
        { label: '启用', value: 1 },
        { label: '停用', value: 2 },
      ],
      selectList: [],
      statusObj: {
        1: '启用',
        2: '停用',
      },
      statusColorObj: {
        2: 'warning',
        1: 'success',
      },
      totalShow: true,
      loading: false,
      typeOptions: [],
      lawOptions: [],
      productOptions: [],
      total: 0,
      listQuery: {
        page: 1,
        pageSize: 10,
        likeName: '',
        productId: '',
        status: '',
      },
      tableData: [],
      savePerm: findBtnPerm('19'),
      updatePerm: findBtnPerm('20'),
      delPerm: findBtnPerm('21'),
    }
  },
  created() {
    this.getList()
    this.getAllProduct()
  },
  methods: {
    del(row) {
      if(row.status === 1) {
        error('声明为启用状态，无法删除')
      }else {
        this.$confirm('是否确认吗', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
          closeOnClickModal: false,
        }).then(() => {
          DeleteRelease({ id: row.id }).then(res => {
            const resData = res.data
            if (resData.code === 0) {
              success(resData.msg)
              this.getList()
            } else {
              error(resData.msg)
            }
          })
        })
      }
    },
    getAllProduct() {
      getAllProduct().then(res => {
        const resData = res.data
        if (resData.code === 0) {
          resData.data.forEach(r => {
            this.productOptions.push({ label: r.name, value: r.id })
          })
        }
      })
    },
    getList() {
      this.loading = true
      getStatementPublishListByPage(this.listQuery).then(res => {
        this.loading = false
        this.tableData = res.data.data.list
        this.total = Number(res.data.data.total)
      }).catch(() => {
        this.loading = false
      })
    },
    handleFilter() {
      this.listQuery.page = 1
      this.getList()
    },

    saveDialogShow(type, row) {
      this.$refs.statementPulish.dialogVisible = true
      if (type === 2) {
        this.$refs.statementPulish.form.id = row.id
        this.$refs.statementPulish.form.statementId = row.statementId
        this.$refs.statementPulish.form.version = row.version
        this.$refs.statementPulish.form.remark = row.remark
      }
    },
    switchStatus(id, status) {
      const msg = status === 1 ? '停用' : '启用'
      // const statusCopy = status ? 0 : 1
      this.$confirm(`是否确认${msg}`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        closeOnClickModal: false,
      }).then(() => {
        updateStatementPublishStatus({ id }).then(res => {
          const resData = res.data
          if (resData.code === 0) {
            success(resData.msg)
            this.getList()
          } else {
            error(resData.msg)
          }
        })
      })
    },
  },
}
</script>

<style scoped>

</style>
